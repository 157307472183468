import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { renderMetaTags } from "react-datocms";
import { Helmet } from "react-helmet";

const preloadSources = [
  { href: "/fonts/National2Narrow/black.woff2", as: "font" },
  { href: "/fonts/National2Narrow/bold.woff2", as: "font" },
  { href: "/fonts/National2Narrow/regular.woff2", as: "font" },
  { href: "/fonts/Calibre/regular.woff2", as: "font" },
  { href: "/fonts/Calibre/regular-italic.woff2", as: "font" },
  { href: "/fonts/Calibre/semi-bold.woff2", as: "font" },
  { href: "/fonts/Greenberry/greenberry-regular.woff2", as: "font" },
  { href: "/fonts.css", as: "style" },
];

export const SEO = ({ tags = [] }) => {
  const data = useStaticQuery(graphql`
    query {
      data: dato {
        site: _site {
          favicon: faviconMetaTags {
            attributes
            content
            tag
          }
        }
      }
    }
  `);

  const allTags = data ? [...data.data.site.favicon, ...tags] : [...tags];

  return (
    <Helmet
      htmlAttributes={{
        lang: "nl",
      }}
    >
      {renderMetaTags(allTags)}
      {preloadSources.map(({ href, as }) => (
        <link
          key={href}
          rel="preload"
          href={href}
          as={as}
          // eslint-disable-next-line
          crossorigin
        />
      ))}
    </Helmet>
  );
};

SEO.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      content: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        property: PropTypes.string,
        content: PropTypes.string,
      }),
    })
  ),
};

export default SEO;
